import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import DolRegisterMsg from './index';

const Name = styled.span`
  font-weight: 600;
`;

const FeedbackMsg = props => {
  const { name } = props;

  if (name) {
    return (
      <>
        <p>
          Cảm ơn <Name>{name}</Name> đã để lại đánh giá về trải nghiệm của bạn
          tại DOL.
        </p>
        <p>
          Đây là 1 phần quan trọng giúp DOL có thể hoàn thiện chương trình hơn,
          mang lại cho <Name>{name}</Name> và mọi người trải nghiệm tuyệt vời
          hơn nữa.
        </p>
      </>
    );
  }

  return (
    <>
      <p>Cảm ơn bạn đã để lại đánh giá về trải nghiệm của bạn tại DOL.</p>
      <p>
        Đây là 1 phần quan trọng giúp DOL có thể hoàn thiện chương trình hơn,
        mang lại cho bạn và mọi người trải nghiệm tuyệt vời hơn nữa.
      </p>
    </>
  );
};

FeedbackMsg.propTypes = {
  name: PropTypes.string
};

const DolRegisterFeedbackSuccess = props => {
  const { bannerImg, name } = props;
  return (
    <DolRegisterMsg
      title="Cảm ơn đánh giá của bạn về DOL"
      bannerImg={bannerImg}
      topMsg={<FeedbackMsg name={name} />}
    />
  );
};

DolRegisterFeedbackSuccess.propTypes = {
  bannerImg: PropTypes.string,
  name: PropTypes.string
};

export default DolRegisterFeedbackSuccess;
